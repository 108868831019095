export const projectsData = [
  {
    id: 1,
    title: "Wishing Well",
    date: "Décembre 2020",
    languages: ["React", "API REST Symfony","Sass"],
    infos:
      "Avec Wishing Well vous allez gagner en temps et en sérénité.Pour tous et en toutes occasions, faites  le cadeau qui fait plaisir",
    img: "./assets/img/projet-2.png",
    link: "http://54.208.46.110/",
  },
  {
    id: 2,
    title: "APP TEST",
    date: "octobre 2021",
    languages: ["React", "Symfony","Api-Platform"],
    infos:
      "Apllication fiche client, frontend React et Backend API Symfony ",
    img: "./assets/img/projet-1.png",
    link: "https://hidden-brushlands-58075.herokuapp.com/",
  },
  {
    id: 3,
    title: "Kryzall",
    date: "avril 2021",
    languages: ["Wordpress", "PHP","Sass"],
    infos:
      "Developpez votre visiblité avec un site sur mesure ",
    img: "./assets/img/projet-3.png",
    link: "http://kryzall.com/",
  },
  
];
