import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import Logo from "../../components/Logo/Logo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Mouse from "../../components/Mouse/Mouse";
import { motion } from "framer-motion";
import cv from "../../assets/images/cv.pdf";
import Button from "../../components/Button";
import "./contact.scss";
import Navigation from "../../components/Navigation/Navigation";
import SocialNetwork from "../../components/SocialNetwork/SocialNetwork";

const Contact = () => {
  const pageTransition = {
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: 300,
    },
  };

  const transition = {
    ease: [0.03, 0.87, 0.73, 0.9],
    duration: 0.6,
  };

  return (
    <main>
      <div className="nav">
      
      <Navigation />
      </div>
      <Mouse />
      <motion.div
        className="contact"
        exit="out"
        animate="in"
        initial="out"
        variants={pageTransition}
        transition={transition}
      >
        <div className="social">
        <SocialNetwork />
        </div>
        <Logo />
        <div className="form">
        <ContactForm />
        </div>
        
        <div className="contact-infos">
          <div className="address">
            <h4>adresse</h4>
            <p>Alsace</p>
            <p>Strasbourg/Colmar/Selestat</p>
          </div>

          <div className="phone">
            <h4>téléphone</h4>
            <CopyToClipboard text="0648255422" className="hover">
              <p
                style={{ cursor: "pointer" }}
                className="clipboard"
                onClick={() => {
                  alert("Téléphone copié !");
                }}
              >
                06 48 25 54 22
              </p>
            </CopyToClipboard>
          </div>

          <div className="email">
            <h4>email</h4>
            <CopyToClipboard
              text="annonier.pierreaxel@gmail.com"
              className="hover"
            >
              <p
                style={{ cursor: "pointer" }}
                className="clipboard"
                onClick={() => {
                  alert("Email copié !");
                }}
              >
                annonier.pierreaxel@gmail.com
              </p>
            </CopyToClipboard>
          </div>

          <div className="pdf">
            <a href={cv} target="blank">
              <Button push="CV à télécharger" />
            </a>
          </div>
        </div>
        
      </motion.div>
    </main>
  );
};

export default Contact;
