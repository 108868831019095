export const tricksData = [
    {
      id: 1,
      title: "Github",
      date: "Décembre 2020",
      languages: ["React"],
      infos:
        "Deployer un site gratuitement sur Github",
        img: "./assets/img/github.png",
      link: "/trick/deploiement-gratuit-github",
    },
    {
      id: 2,
      title: "Api platform",
      date: "mars 2021",
      languages: ["Wordpress", "PHP","Sass"],
      infos:
        "venez vous ressourcer et vous restaurer dans un cadre idyllique ",
      img: "./assets/img/api-platform.png",
      link: "/trick/api-platform",
    },
    {
      id: 3,
      title: "wordPress/react",
      date: "avril 2021",
      languages: ["Wordpress", "PHP","Sass"],
      infos:
        "Deployer React dans Wordpress, c'est possible!",
      img: "./assets/img/wordpress.png",
      link: "/trick/wordpress-react",
    },
    
  ];
  