import React from "react";
import "./tips.scss";



const Tips = ({title,date,languages,infos,text}) => {

  return (
    <div className="tips">
        
        <h1>{title} {date}</h1> 
        <p>{infos}</p>
        <p>{languages}</p>
        <p>{text}</p>

    </div>)
    };

    export default Tips;
        
  