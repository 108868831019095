import React from 'react';
import './partage.scss';

import ButtonsBottom from "../../components/ButtonsBottom/ButtonsBottom";
import DynamicText from "../../components/DynamicText";
import Mouse from "../../components/Mouse/Mouse";
import { motion } from "framer-motion";

//images

import homepage from '../../assets/images/homepage.png';
import script from '../../assets/images/script.png';
import redirection from '../../assets/images/redirection.png';
import route from '../../assets/images/route.png';
import yarn from '../../assets/images/yarn.png';
import npm from '../../assets/images/npm.png';
import react from '../../assets/images/react.png'


const Partage = () => {

  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 100,
    },
    visible: { 
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
      x: -100,
    }
  };

  return (
    <main>
      <Mouse />
      <motion.div
        className="partage"
        initial="initial"
        animate="visible"
        exit="exit"
        variants={variants}
      >
        
        <div className="partage-main">
          <div className="main-content">
            
            <motion.h2 drag onDragEnd dragConstraints={{ left: -250, right: 1250, top: -200, bottom: 250 }}>
              <DynamicText />
            </motion.h2>
          </div>
        </div>
        <article className="repo">

            <p> Ici je vais partager mes petites astuces</p>

        <h1>Déploiement gratuit sur Github</h1>
           <p><img src={react} className="react" alt="logo react" /></p>

        <h2> Le saviez-vous????  ✨ </h2>

        <p>Je m'adresse ici à tous les développeurs web mais surtout au juniors!
        Github vous propose un hébergement gratuit pour vos tests ou portfolio,
        Et oui! ça peut servir, n'est ce pas? 😲</p>

        <a href="https://p-axel.github.io">la preuve</a>

        <p>
          Mais la bonne nouvelle, c'est que ce sera surtout avec une adresse personnalisée avec votre pseudo Github et sur un server plutôt costaud !!
          Pour cela vous allez tout simplement créer un nouveau repository sur Github  mais attention, il vous faudra l'appeler
          exactement de la forme suivante: </p>

        <p><strong> "votre pseudo Github".github.io </strong></p>

        <p>
          On parle bien ici de votre pseudo Github tel qu'il est inscrit dans votre profil Github!
          Ensuite vous pouvez initialiser votre "repos" comme d'habitude mais cette fois, son contenu sera deployé sur votre
          nouveau nom de domaine "pseudo-github".github.io"
          Vous pouvez-y placer une simple page html ou bien le contenu de votre build pour les copains qui composent avec "React" 🔥</p>

        <p> <b>Dans mon exemple ce sera <a href="https://p-axel.github.io/" ><strong>p-axel.github.io</strong></a></b>
        </p>
        </article>

        <article className="deploy">

        <p>
          Pour plus de simplicité, dans votre terminal, je vous propose d'installer "gh-pages" avec la commande :</p>

        <p><img src={yarn} alt="code yarn" /></p>

        <p>Ou bien npm selon votre propre gestionnaire de package</p>

        <p><img src={npm} alt="code npm" /></p>

        <p>Ensuite, allez dans votre fichier package.json dans votre projet

          Il suffira d'y installer 3 petites lignes : </p>

        <p>D'abord, on indique la homepage </p>


        <img src={homepage} alt="code" />

        <p>Ensuite, ces deux lignes pour lancer le build du projet et indiquer la commande du deploiement au terminal</p>

        <p><img src={script} alt="code" /></p>

        <p>
          Et maintenant!?
          Un petit "npm run deploy" dans le terminal !!
          vous mettrez à jour en direct votre site depuis votre terminal!!!
          c'est pas beau la vie??
          Evidemment cette commande est à utiliser après chaque mis à jour de votre site, moyennant quelques secondes ou
                minutes.</p>
        <p>
              Petit Bonus pour les developpeurs <b>React</b> qui utilisent <b>React-Router</b> pour la
              navigation et qui n'ont pas envie de s'emcombrer d'un back-end pour l'occasion:
              Sachez que vous tomberez très certainement sur votre page principale !! Mais surprise, la navigation
              est cassée!😱😱😱 En effet, en phase de déploiement,
              tout passera par le html dans le dossier Public.
              Je vous propose d'écrire une simple ligne de code pour que tout rentre dans l'ordre:</p>


        <p>Vous avez évidemment installé et importé React-Router comme ci-dessous au préalable: </p>

        <p>
          <img src={route} alt="code" />
        </p>

        <p> Il vous faudra alors écrire dans la balise du "Router" (BrowserRouter ici renommé en Router) cette ligne magic ;) </p>
        <p>

          <img src={redirection} alt="code" />
        </p>

        <p>
          Enjoy!!😎
          </p>
        <p>
          <a
            href="https://medium.com/@arijit_chowdhury/deploy-react-app-with-react-router-to-github-pages-for-free-569377f483f"
          >ℹ source
                </a>
        </p>

        </article>
                <ButtonsBottom right={"/projet-1"} />
              </motion.div>
            </main>
          );
        };

        export default Partage;
