import React from 'react';
import Trick from "./Trick/index";
import { tricksData } from "../../data/tricksData";
import './trick.scss';
import Mouse from "../../components/Mouse/Mouse";

const Tricks = (props) => {


  return (

    <main>
      <Mouse />
      <div className="trick" >
          {tricksData.map((item) => (
             <Trick key={item.id} {...item} />
          ))}
      </div>
 
    </main>
  );
};

export default Tricks;

