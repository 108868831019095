import ButtonsBottom from "../../components/ButtonsBottom/ButtonsBottom";
import DynamicText from "../../components/DynamicText";
import Mouse from "../../components/Mouse/Mouse";
import { motion } from "framer-motion";
import "./home.scss";
import Navigation from "../../components/Navigation/Navigation";
import SocialNetwork from "../../components/SocialNetwork/SocialNetwork";
import "../../assets/images/neige.png";
import { NavLink } from "react-router-dom";
import Button from "../../components/Button";

const Home = (props) => {
  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.3 },
      x: -100,
    },

    // eslint-disable-next-line jsx-a11y/iframe-has-title
  };
  return (
    <main>
      <Navigation />
      <Mouse />
      <motion.div
        className="home"
        initial="initial"
        animate="visible"
        exit="exit"
        variants={variants}
      >
        <NavLink
        to="/contact"
        exact
        activeClassName="nav-active"
        className="logo hover"
      >
        <Button push="Contact" />
      </NavLink>
        <SocialNetwork />
        <div className="home-main">
          <div className="main-content">
            <motion.h1
              drag
              onDragEnd
              dragConstraints={{
                left: -250,
                right: 950,
                top: -200,
                bottom: 250,
                
              }}
            >
              Pierre-axel ANNONIER
            </motion.h1>
            <motion.h2
              drag
              onDragEnd
              dragConstraints={{
                left: -250,
                right: 1250,
                top: -200,
                bottom: 250,
              }}
            >
              
              <DynamicText />
            </motion.h2>
          </div>
        </div>
        <ButtonsBottom right={"/projet-1"} />
      </motion.div>
    </main>
  );
};

export default Home;
