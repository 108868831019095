import React from 'react';
import ButtonsBottom from '../../components/ButtonsBottom/ButtonsBottom';
import Logo from '../../components/Logo/Logo';
import Project from '.';
import Mouse from '../../components/Mouse/Mouse';
import './project.scss';

export const Project1 = () => {
  return (
    <main>
      <Mouse />
      <div className="project">
        <Logo />
        <Project id={0} />
        <ButtonsBottom left={'/'} right={'/projet-2'} />
      </div>
    </main>
  );
};

export const Project2 = () => {
  return (
    <main>
      <Mouse />
      <div className="project">
        <Logo />
        <Project id={1} />
        <div className="buttonBottom">
        <ButtonsBottom left={"/projet-1"} right={"/projet-3"} />
        </div>
      </div>
    </main>
  );
};

export const Project3 = () => {
  return (
    <main>
      <Mouse />
      <div className="project">
        <Logo />
        <Project id={2} />
        <ButtonsBottom left={"/projet-2"} right={"/contact"} />
      </div>
    </main>
  );
};
