import React from 'react';
import './notFound.scss';
import Button from '../../components/Button';
import calimero from '../../assets/images/calimero.png';

const index = () => {
    return (
        <div className="notFound">
            <h2>Oups, page introuvable... </h2>
            <img src={calimero} alt="calimero"/>
        
        <a href="/" ><Button push="Go home" /></a>

        
           
        </div>
    );
};

export default index;