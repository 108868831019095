import { useState } from "react";
import emailjs from 'emailjs-com';
import Button from '../Button';
import './contactForm.scss';
  
const ContactForm = () =>{

    const sendEmail = (e) =>{
      e.preventDefault();
      
      emailjs.sendForm('service_04lxb8m', 'template_bal34fp', e.target, 'user_7sLJ16ZNdkYR2QRZD7GNU')
        .then((result) => {
         alert("votre message a bien été envoyé 😎")
            console.log(result.text);
        }, (error) => {
          alert("oups...une erreur s'est produite 😄")
            console.log(error.text);
        });
    }
      
    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

  return (
    
        <form className="contact-form" onSubmit={sendEmail}>
            <h2>contactez-moi</h2>
            <div className="form-content">

          <input 
          type="text" 
           id="name"
           name="name"
           required
           onChange={(e) => setName(e.target.value)}
           placeholder="nom *"
           value={name}
          />
          
          <input 
            type="text"
            id="company"
            name="company"
            onChange={(e) => setCompany(e.target.value)}
            placeholder="société"
            value={company}
            />

          <input 
            type="text"
            id="phone"
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            placeholder="téléphone"
            value={phone}
            />
          
          
          <input 
           type="mail"
           id="email"
           name="email"
           required
           onChange={(e) => setEmail(e.target.value)}
           placeholder="email *"
           value={email}
           /> 

          <textarea 
          id="message"
          name="message"
          onChange={(e) => setMessage(e.target.value)}
          placeholder="message *"
          value={message}
          required />
          <div className="send">
          <Button push="envoyer" type="submit" value="Envoyer" />
          </div>
          </div>
        </form>
      )
  
};

export default ContactForm;


