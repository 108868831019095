
import { motion } from "framer-motion";
import '../trick.scss';
import Button from '../../../components/Button';
import { NavLink } from "react-router-dom";


const Trick = ({title, infos, link, img}) => {
 

  // random background circle
  let left = Math.floor(Math.random() * 200 + 700) + "px";
  let top = Math.floor(Math.random() * 200 + 150) + "px";
  let size = "scale(" + (Math.random() + 0.7) + ")";

  const variants = {
    initial: {
      opacity: 0,
      transition: { duration: 0.5 },
      x: 200,
    },
    visible: { 
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0.4,
      transition: { duration: 0.35 },
      x: -800,
    }
  };

  // random img pop
  let plusMinus = Math.random() > 0.4 ? 1 : -1;
  let imgX = Math.random() * 350 * plusMinus;
  let imgY = Math.random() * 120 * plusMinus;

  const imgAnim = {
    initial: {
      opacity: 0,
      x: imgX,
      y: imgY,
    },
    visible: { 
      opacity: 1,
      x: 0,
      y: 0,
    }
  }

  const transition = {
    ease: [.03,.87,.73,.9],
    duration: .6,
  }

  return (
    <motion.div
      className="trick-main"
      initial="initial"
      animate="visible"
      exit="exit"
      transition={transition}
      variants={variants}
    >
      <div className="trick-content">
        <h1>{title}</h1>
        <div className="button-container"> 
        
          <NavLink to={link} target="_blank" rel="noopener noreferrer" className="hover">
          < Button push = "voir l'astuce ici"/>
          </NavLink>
        </div>
      </div>
      <motion.div 
        className="img-content"
        initial="initial"
        animate="visible"
        variants={imgAnim}
        transition={{duration: 1.4}}
      >
        <div className="img-container hover">
          <span>
            <h3>{title}</h3>
            <p>{infos}</p>
          </span>
          <div className="img">
          <img src={img}  alt={title}  />
          
          </div>
        </div>
       
       
      </motion.div>
      <span className="random-circle" style={{ left, top, transform: size }}></span>
    </motion.div>
  );
};

export default Trick;