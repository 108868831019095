import React from "react";
import { NavLink } from "react-router-dom";


const ButtonsBottom = (props) => {
  return (
    <div className="scroll-bottom">
      <div className="sb-main">

        <div className="arrow-left">
        {props.left && (
          <NavLink to={props.left} className="left hover">
            <span><i className="fas fa-long-arrow-alt-left"></i></span>
          </NavLink>
        )}
        </div>

        <p className="center">NAV</p>

          <div className="arrow-right">
        {props.right && (
          <NavLink to={props.right} className="right hover">
            <i className="fas fa-long-arrow-alt-right"></i>
          </NavLink>
        )}
        </div>
      </div>
    </div>
  );
};

export default ButtonsBottom;
