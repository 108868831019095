import React, { useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import Home from "./pages/Home/Home";
import {Project1, Project2, Project3 } from "./pages/Project/Projects";
import Contact from "./pages/Contact";
import { AnimatePresence } from "framer-motion";
import Partage from './pages/Partage/index.js';
import NotFound from './pages/notFound';
import Tricks from "./pages/Tricks";
import Tips from "./pages/Tips";


const App = () => {



  require('dotenv').config()
  const location = useLocation();
  let history = useHistory();

  useEffect(() => {
    const handleScrollToElement = (e) => {
      console.log(e.wheelDeltaY);
      const url = window.location.origin + "/";

      const wheelRouter = (after, before) => {
        if (e.wheelDeltaY < 0) {
          setTimeout(() => {
            history.push(after);
          }, 500);
        } else if (e.wheelDeltaY > 0) {
          setTimeout(() => {
            history.push(before);
          }, 600);
        }
      };

      switch (window.location.href.toString()) {
        case url:
          if (e.wheelDeltaY < 0) {
            setTimeout(() => {
              history.push("projet-1");
            }, 500);
          }
          break;
        case url + "projet-1":
          wheelRouter("projet-2", "/");
          break;
        case url + "projet-2":
          wheelRouter("projet-3", "projet-1");
          break;

        case url + "projet-3":
          wheelRouter("contact", "projet-2");
          break;  
        
        case url + "contact":
          if (e.wheelDeltaY > 0) {
            setTimeout(() => {
              history.push("projet-3");
            }, 500);
          }
          break;
        default:
          console.log("nothing");
      }
    };
    window.addEventListener("wheel", handleScrollToElement);
  }, [history]);

  return (
    <div className="app">
    <AnimatePresence>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Home} />
        <Route exact path="/projet-1" component={Project1} />
        <Route exact path="/projet-2" component={Project2} />
        <Route exact path="/projet-3" component={Project3} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/partage" component={Partage} />
        <Route exact path="/trick" component={Tricks} />
        <Route exact path="/trick/deploiement-gratuit-github" component={Tips} />
        <Route exact path="/trick/api-platform" component={Tips} />
        <Route exact path="/trick/wordpress-react" component={Tips} />
        <NotFound/>  
      </Switch>
    </AnimatePresence>
    </div>
  );
};

export default App;
