import React from "react";
import { NavLink } from "react-router-dom";
import "./navigation.scss";
import logo1 from "../../assets/images/logo1.png";


const Navigation = () => {
  return (
    <div className="navigation">
    <div className="logo">
      <NavLink to="/" exact activeClassName="nav-active" className="logo hover">
        <li>
          <img src={logo1} width={200} alt="" />
        </li>
      </NavLink>
      </div>

      <div className="projet">
      <ul>
      
        <li className="nav-portfolio">
          <h3>Projets</h3>
          <ul className="nav-projects">
            <NavLink
              to="/projet-1"
              activeClassName="nav-active"
              className="hover"
            >
              <li>Wishing well</li>
            </NavLink>
            <NavLink
              to="/projet-2"
              activeClassName="nav-active"
              className="hover"
            >
              <li>APP TEST</li>
            </NavLink>
            <NavLink
              to="/projet-3"
              activeClassName="nav-active"
              className="hover"
            >
              <li>Kryzall</li>
            </NavLink>
          </ul>
        </li>
        
      </ul>
    </div>
    </div>
  );
};

export default Navigation;
