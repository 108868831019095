import React from 'react';
import './button.scss';

const Button = ({push}) => {
    return (
        <div>
            <button>
                   {push}
            </button>
        </div>
    );
};

export default Button;